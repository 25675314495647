import React, { useEffect, useState } from 'react';
import { Pie } from '@antv/g2plot';
import { InfoSpan, MainDivDonut, StyledHeading, DonutGraph } from '../styles';

const PieChart = ({ props }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const data = [
      { type: 'Débito', value: props.debit.count },
      { type: 'Crédito à Vista', value: props.credit.count },
      { type: 'Crédito Parcelado', value: props.parcelCredit.count },
      { type: 'PIX', value: props.pix.count },
    ].filter(item => item.value > 0); 

    const calculateFontSize = () => {
      const rules = [
        { max: 1600, min: 1300, fontSize: '18px' },
        { max: 1299, min: 1000, fontSize: '15px' },
        { max: 999, min: 768, fontSize: '11px' },
        { max: 769, min: 450, fontSize: '16px' },
        { max: 449, min: 280, fontSize: '12px' },
        { max: 279, fontSize: '7px' }, 
      ];
      const matchedRule = rules.find(({ max, min = 0 }) => windowWidth <= max && windowWidth >= min);
      return matchedRule ? matchedRule.fontSize : '22px'; 
    };
  
    const piePlot = new Pie('pie-container', {
      appendPadding: 10,
      data,
      angleField: 'value',
      colorField: 'type',
      radius: 1,
      innerRadius: 0.6,
      label: {
        visible: false,
      },
      color: ({ type }) => {
        const colorMap = {
          Débito: '#f39c12',
          'Crédito à Vista': '#0073b7',
          'Crédito Parcelado': '#00a65a',
          PIX: '#4B0082',
        };
        return colorMap[type] || '#d2d6de';
      },
      interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
      statistic: {
        title: false,
        content: {
          style: {
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: calculateFontSize(),
            fontWeight: '500',
          },
          content: 'APROVADAS',
        },             
      },
      responsive: true, 
    });

    piePlot.render();

    return () => {
      piePlot.destroy();
    };
  }, [props, windowWidth]);

  return (
    <MainDivDonut
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'translateY(-5px)';
        e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'none';
        e.currentTarget.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.1)';
      }}
    >
      <StyledHeading>
        Transações por Tipo
      </StyledHeading>
      <DonutGraph
        id="pie-container"
      ></DonutGraph>
    </MainDivDonut>
  );
};

export default PieChart;
