import React from 'react';
import ReactDOM from 'react-dom/client';
import { GlobalStyle } from './style/main';
import AppRoutes from './routes/routers';
import WindowDimensionsProvider from './hooks/windowDimensionsProvider'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <WindowDimensionsProvider>
    <GlobalStyle/>
    <AppRoutes/>
  </WindowDimensionsProvider>
);
