import React, { useEffect, useState, useContext, useCallback } from 'react';
import _, { set } from 'lodash';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookies';
import { DatePicker, Button, Select, Spin, Typography, Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import * as transactionsRoutes from '../../providers/api/transactions/transactions';
import { checkLogin } from '../../providers/api/auth/auth';
import { WindowDimensionsContext } from '../../hooks/windowDimensionsProvider'
import {
    PageContainer,
    Card,
    ExtendedCard,
    CardTitle,
    CardValue,
    DateButtonGroup,
    DashboardContainer,
    MarginExtendedCard,
    Container
} from './styles';
import { getCompaniesService } from '../../providers/api/companies';
import PieChart from '../dashboard/graphics/donut';
import GroupedStackedColumnChart from '../dashboard/graphics/collumn';

export const Dashboard = () => {
    const routerParams = useParams();
    const navigate = useNavigate();
    const windowDimensions = useContext(WindowDimensionsContext);
    const lastDaysCurrent = 1;
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const initialDateValue = new Date(new Date().getTime() - lastDaysCurrent * oneDay);

    const [companies, setCompanies] = useState([]);
    const [companySelected, setCompanySelected] = useState(null);
    const [cardTransactions, setCardTransactions] = useState({
        debit: { amount: 0, count: 0 },
        credit: { amount: 0, count: 0 },
        parcelCredit: { amount: 0, count: 0 },
        pix: { amount: 0, count: 0 },
        anticipations: { amount: 0, count: 0 },
        count: 0,
    });
    const [loading, setLoading] = useState(false);
    const [totalScheduledes, setTotalScheduledes] = useState(0);
    const [initialDate, setInitialDate] = useState(dayjs(initialDateValue));
    const [finalDate, setFinalDate] = useState(dayjs(now));
    const patternColorsThemes = {
        indigo: [
            '#484c7f',
            '#a0c1b8',
            '#7394b9',
            '#79b6bd',
            '#d8d419'
        ],
        blue: [
            '#ED5782',
            '#e5df88',
            '#7258db',
            '#a6a6a4',
            '#c9b8b8'
        ],
        orange: [
            '#ef7e56',
            '#44558f',
            '#ded5c4',
            '#f59292',
            '#90a4ae'
        ],
        tradewind: [
            '#6aab9c',
            '#f7b36b',
            '#97B0AA',
            '#4D6E94',
            '#81A1CA',
            '#ED5782'
        ],
        cyan: [
            '#00bdaa',
            '#c1c0b9',
            '#537791',
            '#9cbf3b',
            '#cdb60c'
        ],
        blush: [
            '#ED5782',
            '#e5df88',
            '#726a95',
            '#a6a6a4',
            '#c9b8b8'
        ],
        monalisa: [
            '#ED5782',
            '#e5df88',
            '#726a95',
            '#a6a6a4',
            '#c9b8b8'
        ],
        green: [
            '#7EB93F',
            '#b9bc6d',
            '#427a5b',
            '#4e7376',
            '#a6a6a4'
        ],
        red: [
            '#ff5959',
            '#ffad5a',
            '#4f9da6',
            '#c06c84',
            '#f67280'
        ]
    }
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [loadingCards, setLoadingCards] = useState(false);
    const [loadingPix, setLoadingPix] = useState(false);
    const [loadingAnticipations, setLoadingAnticipations] = useState(false);
    const [loadingScheduledes, setLoadingScheduledes] = useState(false);
    const [currentsColorTheme, setCurrentColorsTheme] = useState([]);
    const [totalTransactions, setTotalTransactions] = useState(0);
    const [transactionsAmount, setTransactionsAmount] = useState(0);
    const [avarageTicket, setAvarageTicket] = useState(0);
    const [transactionsByDate, setTransactionByDate] = useState([]);
    const [transactionsByBrand, setTransactionByBrand] = useState([]);
    const [transactionType, setTransactionType] = useState({});
    const [transactionTypeData, setTransactionTypeData] = useState([]);
    const [cardTransactionStatus, setCardTransactionStatus] = useState([]);
    const [messageInfo, setMessageInfo] = useState('');

    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });

    const handleCheckAuth = useCallback(async () => {
        const token = routerParams.token || Cookies.getItem('token');

        if (token) {
            if (!Cookies.getItem('colorTheme')) {
                Cookies.setItem('colorTheme', routerParams.colorTheme)
            }

            const response = await checkLogin(token);

            if (!response.status) {
                Cookies.removeItem('token');
                return navigate("/login");
            }

        } else {
            navigate("/login");
        }

    }, [navigate, routerParams]);

    const getDateFormat = useCallback((date) => {
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${date.getFullYear()}-${month}-${day}`
    }, [])

    const handleTransactions = useCallback(async () => {
        if (isFirstLoad) {
            setIsFirstLoad(false);
            const hasReloaded = sessionStorage.getItem('hasReloaded');
    
            if (!hasReloaded) {
                sessionStorage.setItem('hasReloaded', 'true');
                setTimeout(() => {
                    window.location.reload();
                }, 900);
            }
        }
        await mapperCompanies();
        setLoadingCards(true);
        setLoadingPix(true);
        setLoadingAnticipations(true);
        setLoadingScheduledes(true);

        await handleCheckAuth();

        let transactionsCount = 0;
        let transactionsAmount = 0;
        let transactionsByDate = [];
        let transactionsByBrand = [];
        let transactionType = {};
        let transactionTypeData = [];
        let cardByTransactionStatus = [];

        setMessageInfo('');

        if (!initialDate || !finalDate) {
            setLoadingCards(false);
            setLoadingPix(false);
            setLoadingAnticipations(false);
            setLoadingScheduledes(false);
            alert('Informe a data de início e fim para buscar as informações.');
            return;
        }

        let filter = {
            initialDate: getDateFormat(initialDate.toDate()),
            finalDate: getDateFormat(finalDate.toDate()),
            idCompany: companySelected?.value || Cookies.getItem('companyId'),
        };

        try {
            const [cards, pix, anticipations, scheduledes] = await Promise.all([
                transactionsRoutes.getCardsTransactions(filter).finally(() => setLoadingCards(false)),
                transactionsRoutes.getPixTransactions(filter).finally(() => setLoadingPix(false)),
                transactionsRoutes.getAnticipationTransactions(filter).finally(() => setLoadingAnticipations(false)),
                transactionsRoutes.getSchedules(filter).finally(() => setLoadingScheduledes(false)),
            ]);

            if (!cards.status || !pix.status || !anticipations.status || !scheduledes.status) {
                setMessageInfo('Diminua o filtro de data de início e fim para atualizar as informações.');
                return;
            }
            setCardTransactions(cards.transactionData.amountPerType);

            if (cards.status) {
                transactionsCount += cards.transactionData.count;
                transactionsAmount += cards.transactionData.amount;
                transactionsByDate = transactionsByDate.concat(cards.transactionData.transactionsDate);
                transactionsByBrand = transactionsByBrand.concat(cards.transactionData.transactionsBrand);
                transactionType = _.merge(transactionType, cards.transactionData.transactionsType);
                transactionTypeData = transactionTypeData.concat(cards.transactionData.transactionTypeData);
                cardByTransactionStatus = cardByTransactionStatus.concat(cards.transactionData.cardByTransactionStatus);
            }

            if (pix.status) {
                transactionsCount += pix.transactionData.count;
                transactionsAmount += pix.transactionData.amount;
                transactionsByDate = transactionsByDate.concat(pix.transactionData.transactionsDate);
                transactionType = _.merge(transactionType, pix.transactionData.transactionsType);
                transactionTypeData = transactionTypeData.concat(pix.transactionData.transactionTypeData);
            }

            if (cards.status || pix.status) {
                setTotalTransactions(transactionsCount);
                setTransactionsAmount(transactionsAmount);
                setAvarageTicket(transactionsAmount / transactionsCount);
                setTransactionByDate(transactionsByDate);
                setTransactionByBrand(transactionsByBrand);
                setTransactionType(transactionType);
                setTransactionTypeData(transactionTypeData);
                setCardTransactionStatus(cardByTransactionStatus);
            }

            const filterAnticipations = anticipations.transactionData.filter(anticipation => anticipation.paymentDate >= filter.initialDate && anticipation.paymentDate <= filter.finalDate);

            const totalAnticipationsAmount = filterAnticipations.reduce((acc, curr) => acc + curr.amount, 0);
            setCardTransactions(prevState => ({
                ...prevState,
                anticipations: {
                    amount: totalAnticipationsAmount,
                    count: filterAnticipations.length,
                },
            }));

            const filterScheduledes = scheduledes.transactionData.filter(scheduled => scheduled.payment_date >= filter.initialDate && scheduled.payment_date <= filter.finalDate);

            const totalScheduledes = filterScheduledes.reduce((acc, curr) => acc + curr.valor_parcela, 0);
            setTotalScheduledes(totalScheduledes);
        } catch (error) {
            console.error('Erro ao carregar transações:', error);
            setMessageInfo('Ocorreu um erro ao buscar os dados. Tente novamente mais tarde.');
        }
    }, [companySelected, initialDate, finalDate, getDateFormat, handleCheckAuth]);


    const handleChartColors = () => {
        const pattern = Cookies.getItem('colorTheme');
        setCurrentColorsTheme(patternColorsThemes[pattern] ? patternColorsThemes[pattern] : { scheme: 'nivo' });
    }

    const disabledDate = useCallback((current) => {
        return current.toDate().getTime() > new Date().getTime();
    }, []);

    const handleCompany = useCallback((idCompany) => {
        const companySelect = companies.find(company => company.value === idCompany)
        if (companySelect) {
            setCompanySelected(companySelect)
        }
    }, [companies]);

    const handleFilterDate = useCallback((prop, value) => {
        setMessageInfo('')

        if (prop === 'initial') {
            setInitialDate(value)
        }
        if (prop === 'final') {
            setFinalDate(value)
        }
    }, []);

    const handleCompanies = useCallback(async () => {
        setLoading(true);
        const companyId = Cookies.getItem('companyId');
        const { companies } = await getCompaniesService(companyId)
        const mapperCompanies = companies.map(company => ({
            label: company.companyName,
            value: company.id,
        }))

        setCompanies(mapperCompanies);
        const companySelect = mapperCompanies.find(company => company.value === companyId);
        setCompanySelected(companySelect)
        setLoading(false);
    }, []);

    const mapperCompanies = useCallback(async () => {
        const companyId = Cookies.getItem('companyId');
        const { companies } = await getCompaniesService(companyId)
        const mapperCompanies = companies.map(company => ({
            label: company.companyName,
            value: company.id,
        }))

        setCompanies(mapperCompanies);
    }, []);

    useEffect(() => {
        async function getTransactions() {
            await handleCompanies();
            await handleTransactions();
        }
        getTransactions()
    }, [])

    useEffect(function getChartColors() {
        handleChartColors();
    }, [])

    return (
        <>
            <Spin
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30vh' }}
                indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />}
                spinning={loading}
            />
            {!loading && (
                <PageContainer>
                    <DashboardContainer>
                        <div>
                            <Container
                                className="d-flex justify-content-between align-items-center"
                                style={{ gap: 10 }}
                            >
                                <Select
                                    placeholder="Selecione a companhia"
                                    notFoundContent="Carregando..."
                                    options={companies}
                                    onChange={handleCompany}
                                    value={companySelected}
                                    style={{
                                        width: '100%',
                                        height: 48,
                                        maxWidth: 300,
                                    }}
                                />
                                <DateButtonGroup className="d-flex align-items-center" style={{ gap: 10 }}>
                                    <DatePicker
                                        placeholder="Data inicial"
                                        format="DD/MM/YYYY"
                                        value={initialDate}
                                        disabledDate={disabledDate}
                                        onChange={(val) => handleFilterDate('initial', val)}
                                        style={{
                                            height: 48,
                                            width: '100%',
                                            maxWidth: 170,
                                        }}
                                    />

                                    <DatePicker
                                        placeholder="Data final"
                                        format="DD/MM/YYYY"
                                        value={finalDate}
                                        disabledDate={disabledDate}
                                        onChange={(val) => handleFilterDate('final', val)}
                                        style={{
                                            height: 48,
                                            width: '100%',
                                            maxWidth: 170,
                                        }}
                                    />

                                    <Button
                                        style={{
                                            height: 48,
                                            width: 50,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        type="primary"
                                        onClick={handleTransactions}
                                        disabled={messageInfo}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            style={{ width: 20, height: 20 }}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                                            />
                                        </svg>
                                    </Button>
                                </DateButtonGroup>
                            </Container>
                        </div>
                        {messageInfo && <p>{messageInfo}</p>}
                        <div>
                            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-5 g-4">
                                <div className="col">
                                    <Card>
                                        <CardTitle>
                                            <span>Total de Transações</span>
                                            {loadingCards ? (
                                                <span>Carregando...</span>
                                            ) : (
                                                <span>{cardTransactions?.count || 0}</span>
                                            )}
                                        </CardTitle>
                                        <CardValue>
                                            {loadingCards
                                                ? "Carregando..."
                                                : formatter.format(transactionsAmount || 0)}
                                        </CardValue>
                                    </Card>
                                </div>
                                <div className="col">
                                    <Card>
                                        <CardTitle>
                                            <span>Débito</span>
                                            {loadingCards ? (
                                                <span>Carregando...</span>
                                            ) : (
                                                <span>{cardTransactions?.debit?.count || 0}</span>
                                            )}
                                        </CardTitle>
                                        <CardValue>
                                            {loadingCards
                                                ? "Carregando..."
                                                : formatter.format(cardTransactions?.debit?.amount || 0)}
                                        </CardValue>
                                    </Card>
                                </div>
                                <div className="col">
                                    <Card>
                                        <CardTitle>
                                            <span>Crédito à Vista</span>
                                            {loadingCards ? (
                                                <span>Carregando...</span>
                                            ) : (
                                                <span>{cardTransactions?.credit?.count || 0}</span>
                                            )}
                                        </CardTitle>
                                        <CardValue>
                                            {loadingCards
                                                ? "Carregando..."
                                                : formatter.format(cardTransactions?.credit?.amount || 0)}
                                        </CardValue>
                                    </Card>
                                </div>
                                <div className="col">
                                    <Card>
                                        <CardTitle>
                                            <span>Crédito Parcelado</span>
                                            {loadingCards ? (
                                                <span>Carregando...</span>
                                            ) : (
                                                <span>{cardTransactions?.parcelCredit?.count || 0}</span>
                                            )}
                                        </CardTitle>
                                        <CardValue>
                                            {loadingCards
                                                ? "Carregando..."
                                                : formatter.format(cardTransactions?.parcelCredit?.amount || 0)}
                                        </CardValue>
                                    </Card>
                                </div>
                                <div className="col">
                                    <Card>
                                        <CardTitle>
                                            <span>PIX</span>
                                            {loadingCards ? (
                                                <span>Carregando...</span>
                                            ) : (
                                                <span>{cardTransactions?.pix?.count || 0}</span>
                                            )}
                                        </CardTitle>
                                        <CardValue>
                                            {loadingCards
                                                ? "Carregando..."
                                                : formatter.format(cardTransactions?.pix?.amount || 0)}
                                        </CardValue>
                                    </Card>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-3 g-4">
                                <div className="col">
                                    <ExtendedCard>
                                        <CardTitle>
                                            <span>Ticket Médio</span>
                                        </CardTitle>
                                        <CardValue>
                                            {loadingCards
                                                ? "Carregando..."
                                                : formatter.format(avarageTicket || 0)}
                                        </CardValue>
                                    </ExtendedCard>
                                </div>
                                <div className="col">
                                    <MarginExtendedCard>
                                        <CardTitle>
                                            <span>Total Antecipações</span>
                                            {loadingAnticipations ? (
                                                <span>Carregando...</span>
                                            ) : (
                                                <span>{cardTransactions?.anticipations?.count || 0}</span>
                                            )}
                                        </CardTitle>
                                        <CardValue>
                                            {loadingAnticipations
                                                ? "Carregando..."
                                                : formatter.format((cardTransactions?.anticipations?.amount || 0) / 100)}
                                        </CardValue>
                                    </MarginExtendedCard>
                                </div>
                                <div className="col">
                                    <MarginExtendedCard>
                                        <CardTitle>
                                            <span>Receita Total</span>
                                        </CardTitle>
                                        <CardValue>
                                            {loadingScheduledes
                                                ? "Carregando..."
                                                : formatter.format((totalScheduledes || 0) / 100)}
                                        </CardValue>
                                    </MarginExtendedCard>
                                </div>
                            </div>
                        </div>

                        <div>
                            {loadingCards ? (
                                <div>Carregando gráficos...</div>
                            ) : (
                                <div className="row g-4">
                                    <div className="col-md-8 col-12">
                                        <GroupedStackedColumnChart
                                            transactions={transactionsByDate || []}
                                            status={cardTransactionStatus || []}
                                        />
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <PieChart props={cardTransactions || {}} />
                                    </div>
                                </div>
                            )}
                        </div>


                    </DashboardContainer>
                </PageContainer>
            )}
        </>
    )
}
