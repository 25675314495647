
import { createGlobalStyle } from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';


export const GlobalStyle = createGlobalStyle`
    
    body {  
        background-color: #FAFAFA;
        font-family: 'Montserrat', sans-serif;
      }
      
      @media (max-width: 1920px){
  .container {
    width: 1230px;
  }
}

@media (max-width: 992px){
  .container {
    width: 960px;
    max-width: 100%;
  }
}

.container {
   position: relative;
   margin-left: auto;
   margin-right: auto;
}


    .page-loader{
       position: absolute;
    } 
`;