import styled, { css } from 'styled-components'
import { Statistic, Select } from 'antd';
import { ResponsiveBar } from '@nivo/bar';

export const PageContainer = styled.div`
  margin-top: 2vw;
  display: flex;
  flex-direction: column;

  @media (max-width: 1500px) and (max-height: 900px) {
    margin-top: 4vw;
  }

`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  padding: 5px;

  .left-group,
  .right-group {
    display: flex;
    align-items: center;
  }

  .left-group {
    gap: 16px;
  }

  .right-group {
    margin-left: auto;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;

    .right-group {
      margin-left: 0;
      justify-content: flex-end;
    }
  }
`;

export const AntdSelect = styled(Select)`
  ${(props) =>
    props.windowDimensions.width >= 1000 ? 'width: 500px;' : 'width: 159px;'}
`;

export const TotalsContainer = styled.div`
  display: flex;
  ${(props) =>
    props.windowDimensions.width >= 1000
      ? css``
      : css`
          flex-direction: column;
          align-items: center;
          margin-bottom: 48px;
        `}
  justify-content: space-evenly;
  width: 800px;
  margin-top: 32px;
`;

export const TotalItem = styled(Statistic)`
  ${(props) =>
    props.windowDimensions.width >= 1000
      ? css`
          border-right: 1px solid black;
        `
      : css`
          border-bottom: 1px solid black;
          text-align: center;
        `}
  padding: 32px;
`;

export const ChartsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  ${(props) =>
    props.windowDimensions.width >= 1000
      ? css`
          margin-top: 6px;
        `
      : css`
          flex-direction: column;
          align-items: center;
        `}
`;

export const Chart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 280px;
  margin-top: 32px;
`;

export const ChartTitle = styled.div`
  font-weight: bolder;
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
`;

export const Bar = styled(ResponsiveBar)``;

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 15px;
    gap: 15px;
    margin: 20px 0;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 20px;
  width: 100%;

  @media (max-width: 768px) {
    justify-content: center;
    gap: 15px;
  }
`;

export const Card = styled.div`
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  width: 100%;
  text-align: left;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const ExtendedCard = styled.div`
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 15px;
  width: 100%;
  text-align: left;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 48em) {
    max-width: 100%;
  }
`;

export const MarginExtendedCard = styled(ExtendedCard)`
  margin-left: 0;

  @media (max-width: 48em) {
    margin-left: 0;
  }
`;

export const CardTitle = styled.h4`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;

  @media (max-width: 1200px) {
    font-size: 12px;
  }

  @media (max-width: 1050px) {
    font-size: 10px;
  }

  @media (max-width: 885px) {
    font-size: 9px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const CardValue = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: black;

  @media (max-width: 1200px) {
    font-size: 20px;
  }

  @media (max-width: 1050px) {
    font-size: 16px;
  }

  @media (max-width: 885px) {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const MainDivDonut = styled.div`
  width: 100%;
  background: #ffffff;
  margin 0 auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  transition: transform 0.2s, box-shadow 0.2s;
`;

export const MainDivCollumn = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  transition: transform 0.2s, box-shadow 0.2s;
`;

export const StyledHeading = styled.h3`
  text-align: left;
  margin-bottom: 25px;
  margin-left: 30px;
  font-weight: 500;
  position: relative;
  font-size: 20px;

  @media (max-width: 768px) {
    font-size: 19px;
    margin-left: 10px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    margin-left: 5px;
  }
`;

export const CollumGraph = styled.div`
  height: 310px;
`;

export const DonutGraph = styled.div`
  height: 320px;

  @media (max-width: 849px) {
    height: 300px;
  }
`;

export const DateButtonGroup = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 675px) {
        margin-top: 10px;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
`;

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 675px) {
        flex-direction: column;
        align-items: stretch;
    }
`;