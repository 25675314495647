import axios from 'axios';
import { DASHBOARD_API_BASE_URL, getHeaders } from '..';


export async function getCompaniesService(companyId) {
  try {
    if (!companyId) {
      throw new Error('Invalid data');
    }

    const attributes = ["id", "isMatriz", "idMatriz", "companyName", "channelId"];

    const data = await searchCompany({ id: companyId, attributes });

    const matriz = data.find(company => company.isMatriz);

    const subMatriz = data.find(company => company.isMatriz && company.channelId);

    let idMatriz;

    if(subMatriz) {
      idMatriz = subMatriz.id;
    } else {
      idMatriz = matriz ? matriz.id : data[0].idMatriz;
    }

    const companies = await searchCompany({ idMatriz, id: companyId, attributes });

    if (subMatriz) {
      companies.push(subMatriz);
    }
    return {
      companies,
      status: 200,
    }
  } catch (error) {
    return {
      companies: [],
      status: 400,
    }
  }
}

async function searchCompany(params) {
  const { data } = await axios.post(`${DASHBOARD_API_BASE_URL}/companies`, {
    id: params.id,
    idMatriz: params.idMatriz,
    attributes: params.attributes || [],
  }, {
    headers: getHeaders(),
  });
  return data;
}