import React, { useEffect, useRef, useMemo } from 'react';
import { Column } from '@antv/g2plot';
import { InfoSpan, MainDivCollumn, StyledHeading, CollumGraph } from '../styles';

const GroupedStackedColumnChart = ({ transactions }) => {
  const chartContainerRef = useRef(null);

  const StatusTransaction = {
    APROVADA: 'Aprovada',
    CANCELADA: 'Cancelada',
    ESTORNADA: 'Estornada',
  };
  
  const data = useMemo(() => {
    if (!transactions || transactions.length === 0) return [];
  
    const statusList = Object.keys(StatusTransaction);
    const monthlyCounts = [];
  
    const months = [
      'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
      'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez',
    ];
  
    for (const transaction of transactions) {
      const [year, month] = transaction.date.split('-');
      const monthIndex = parseInt(month, 10) - 1;
      const monthName = months[monthIndex];
  
      for (const status of statusList) {
        if (transaction[status]) {
          const existingRecord = monthlyCounts.find(
            (item) => item.month === monthName && item.status === StatusTransaction[status]
          );
  
          if (existingRecord) {
            existingRecord.value += transaction[status];
          } else {
            monthlyCounts.push({
              month: monthName,
              monthIndex,
              status: StatusTransaction[status],
              value: transaction[status],
            });
          }
        }
      }
    }
  
    return monthlyCounts.sort((a, b) => a.monthIndex - b.monthIndex);
  }, [transactions]);
  

  useEffect(() => {
    if (!data || data.length === 0) return;

    const column = new Column(chartContainerRef.current, {
      data,
      xField: 'month',
      yField: 'value',
      isGroup: false,
      isStack: true,
      seriesField: 'status',
      colorField: 'status',
      color: ['#f39c12', '#0073b7', '#00a65a'],
      responsive: true, 
      appendPadding: [20, 20, 20, 20], 
      legend: {
        position: 'bottom',
        layout: 'horizontal',
        itemSpacing: 8,
        offsetX: 0,
        itemName: {
          style: {
            fontSize: 12,
            fontWeight: '400',
            fill: '#333',
          },
        },
        marker: {
          symbol: 'square',
        },
      },
    });

    column.render();

    return () => column.destroy(); 
  }, [data]);

  return (
  <MainDivCollumn
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'translateY(-5px)';
        e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'none';
        e.currentTarget.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.1)';
      }}
    >
      <StyledHeading
      >
        Transações por Status
      </StyledHeading>
      <CollumGraph
        ref={chartContainerRef}
      />
    </MainDivCollumn>
  );
};

export default GroupedStackedColumnChart;
